import * as React from 'react'
import * as ReactDOM from 'react-dom'
import MiddlewareApp from './components/MiddlewareApp'
import './index.css'

// import { Tab } from './state/sessions/reducers'
import { LinkCreatorProps } from './state/sessions/fetchingSagas'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import Auth from './auth'
// import { exampleSchema } from './fixtures/exampleSchema'

const workspaceConfig = {}
// var token: any;

if (process.env.NODE_ENV !== 'production') {
  /* tslint:disable-next-line */
  // const { whyDidYouUpdate } = require('why-did-you-update')
  // whyDidYouUpdate(React)
}

/* tslint:disable-next-line */
;(window as any)['GraphQLPlayground'] = {
  init(element: HTMLElement, options) {
    isLoggedIn().then(configResult => {
      ReactDOM.render(
        <MiddlewareApp
          setTitle={true}
          showNewWorkspace={false}
          {...options}
          config={configResult.workspace}
          // configString={configString}
          codeTheme={aloi}
          tabs={configResult.tabs}
          createApolloLink={customLinkCreator}
          // schema={exampleSchema}
        />,
        element,
      )
    })
  },
}

const authSession: any = {} // = { loggedIn: false, config: { token: null } };

async function getQueries({ realm, connector }) {
  const url = `https://gateway.aloi.io/${realm}/${connector}/api/graphql/queries`
  const response = await authFetch(url, { method: 'GET' })

  return response.json()
}

async function isLoggedIn() {
  const data = window.location.href.slice(8).split('/')
  const params = {
    realm: data[1],
    connector: data[2],
  }
  keycloakConfig.realm = params.realm
  keycloakConfig.clientId = params.realm + '.graphql'
  await Auth.init(keycloakConfig)
  const config = Auth.setConfiguration(params)
  authSession.loggedIn = true
  authSession.config = config.auth.config

  Object.assign(workspaceConfig, config.workspace)
  const queries = await getQueries(params)

  if (queries && Array.isArray(queries)) {
    queries.forEach(query => {
      query.endpoint = config.workspace.extensions.endpoints.query.url
    })
    if (queries.length > 0) {
      config.tabs = queries
    }
  }

  return config
}

const keycloakConfig = {
  url: 'https://auth.aloi.io/auth',
  realm: '',
  clientId: '',
}
function authFetch(url, options) {
  if (authSession.loggedIn && authSession.config != null) {
    const token = authSession.config.token
    if (!options.headers) {
      options.headers = {}
    }
    options.headers['Authorization'] = `Bearer ${token}`
  }
  return fetch(url, options)
}

const customLinkCreator = (
  session: LinkCreatorProps,
  wsEndpoint?: string,
): { link: ApolloLink } => {
  const { headers, credentials } = session

  const link = new HttpLink({
    uri: session.endpoint,
    fetch: authFetch,
    headers,
    credentials,
  })

  return { link }
}

const aloi = {
  property: '#c5f6e0',
  comment: 'rgba(250, 250, 250, 0.3)',
  punctuation: 'rgba(#c5f6e0,.8)',
  keyword: '#366b6b',
  def: 'rgb(56, 189, 193)',
  qualifier: '#1c92a9',
  attribute: '#b56531',
  number: '#1f6ed6;',
  string: '#d64292',
  builtin: '#d47509',
  string2: '#0b7fc7',
  variable: 'rgb(236, 95, 103)',
  meta: '#b33086',
  atom: 'rgb(245, 160, 0)',
  ws: 'rgba(23, 42, 58, 0.8)',
  selection: '#d1e9fd',
  cursorColor: '#fff',

  background: '#081214',
  editorBackground: '#0a1519',
  resultBackground: '#0f2027',
  leftDrawerBackground: '#081214',
  rightDrawerBackground: '#081214',
  navigationBar: '#0f2027',
  sidebar: '#0f2027',
  sidebarTop: '#0b191c',
  sidebarItemSide: '#d75a58',
  sidebarItemActive: '#162427',
  sidebarItemSessions: '#d75a58',

  drawerText: '#c8f5e1',
  drawerTextInactive: 'rgba(200,245,225, 0.3)',

  tab: '#0f2027',
  tabInactive: '#0b191c',

  button: '#0b191c',
  buttonText: '#c8f5e1',

  primary: '#d75a58',
  secondary: '#c8f5e1',
  light: '#fff',
}
