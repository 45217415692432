import * as Keycloak from 'keycloak-js'
class Auth {
  keycloakConfig: any
  token: string
  constructor() {}
  async init(config) {
    return new Promise((resolve, reject) => {
      this.keycloakConfig = Keycloak(config)
      const keycloak = this.keycloakConfig
      this.keycloakConfig.onAuthSuccess = () => {
        setInterval(() => {
          keycloak.updateToken(30).success(refreshed => {
            // if(refreshed) {
            // }
          })
        }, 30000)
      }
      this.keycloakConfig
        .init({ onLoad: 'login-required' })
        .success(auth => {
          this.token = this.keycloakConfig.token
          resolve(auth)
        })
        .error(() => {
          reject()
        })
    })
  }
  setConfiguration(data) {
    const endpoint = `https://gateway.aloi.io/${data.realm}/${
      data.connector
    }/api/graphql`
    return {
      auth: {
        config: this.keycloakConfig,
      },
      tabs: [{ query: '', name: '', endpoint }],
      workspace: {
        extensions: {
          endpoints: {
            query: {
              url: endpoint,
            },
          },
        },
      },
    }
  }
  getToken(): string {
    return this.token
  }
}
export default new Auth()
